.loader {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 8px;
  height: 40px;
  margin-top: 10px;
  margin-left: 20px;
  color: #fff;
  background: #006fcf;
  border-radius: 4px;
  animation: animloader 0.3s 0.3s linear infinite alternate;
}
.loader::after,
.loader::before {
  position: absolute;
  bottom: 0;
  left: 20px;
  box-sizing: border-box;
  width: 8px;
  height: 40px;
  content: '';
  background: #006fcf;
  border-radius: 4px;
  animation: animloader1 0.3s 0.45s linear infinite alternate;
}
.loader::before {
  left: -20px;
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    height: 40px;
    transform: translateY(0);
  }
  100% {
    height: 10px;
    transform: translateY(30px);
  }
}

@keyframes animloader1 {
  0% {
    height: 48px;
  }
  100% {
    height: 4.8px;
  }
}
