@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;500;600;700&display=swap');

body,
html {
  font-family: 'Manrope', sans-serif;
}

html,
body,
#root {
  @apply box-border;
  @apply h-full;
  @apply w-full;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

iframe::-webkit-scrollbar {
  display: none;
}

.dashboardSpacer,
#dashboard-spacer {
  margin: 0 !important;
}

.tableau-viz-container iframe {
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply w-full;
  @apply h-full;
}

ul:last-child > a {
  @apply border-r-2;
}

.rdp-day_outside {
  @apply hidden;
}

.rdp-day_range_start {
  color: white !important;
  background-color: #006fcf !important;
  border-radius: 50% 0% 0% 50%;
}

.rdp-day_range_end {
  color: white !important;
  background-color: #006fcf !important;
  border-radius: 0% 50% 50% 0%;
}

.rdp-row .rdp-cell:last-child > .rdp-day_selected:not(.rdp-day_range_start) {
  border-radius: 0% 50% 50% 0%;
}

.rdp-row .rdp-cell:first-child > .rdp-day_selected:not(.rdp-day_range_end) {
  border-radius: 50% 0% 0% 50%;
}

.rdp-day {
  width: 35px !important;
  height: 35px !important;
  font-size: 14px;
}

.rdp-cell {
  width: 35px !important;
}

.rdp {
  margin: 0.5em;
}

textarea {
  resize: none;
}

.ScrollbarsCustom-ThumbY {
  position: absolute !important;
  right: 1px !important;
  width: 8px !important;
  background-color: #75787b !important;
}

.ScrollbarsCustom-Track {
  overflow: visible !important;
}

.ScrollbarsCustom-ThumbX {
  position: absolute !important;
  top: 2px !important;
  height: 6px !important;
  background-color: #75787b !important;
}

.__react_component_tooltip {
  padding: 4px 10px !important;
  opacity: 90 !important;
}

.tc9b6367e-dcd3-45c5-8edc-657cb8eba419.place-bottom {
  margin-top: 15px !important;
}

#continue::-webkit-scrollbar-track {
  background-color: #0000001a !important;
}

#continue::-webkit-scrollbar {
  width: 8px;
}

#continue::-webkit-scrollbar-thumb {
  background-color: #75787b !important;
  border-radius: 100px;
}

#stop::-webkit-scrollbar-track {
  background-color: #0000001a !important;
}

#stop::-webkit-scrollbar {
  width: 8px;
}

#stop::-webkit-scrollbar-thumb {
  background-color: #75787b !important;
  border-radius: 100px;
}

#start::-webkit-scrollbar-track {
  background-color: #0000001a !important;
}

#start::-webkit-scrollbar {
  width: 8px;
}

#start::-webkit-scrollbar-thumb {
  background-color: #75787b !important;
  border-radius: 100px;
}

#coach::-webkit-scrollbar-track {
  background-color: #0000001a !important;
}

#coach::-webkit-scrollbar {
  width: 8px;
}

#coach::-webkit-scrollbar-thumb {
  background-color: #75787b !important;
  border-radius: 100px;
}

#copyEmail::-webkit-scrollbar-track {
  background-color: #0000001a !important;
}

#copyEmail::-webkit-scrollbar {
  width: 8px;
}

#copyEmail::-webkit-scrollbar-thumb {
  background-color: #75787b !important;
  border-radius: 100px;
}

@media (max-width: 767px) {
  .mobile-sidebar-visible {
    transform: translateX(0);
    transition: transform 0.3s ease;
  }

  /* Hide the sidebar off-screen by default */
  .mobile-sidebar-invisible {
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
}
