Custom checkbox styles

/* .custom-dropdown .dropdown .dropdown-trigger.arrow.bottom:after {
    content: "\23F7";
}

.custom-dropdown .dropdown .dropdown-trigger.arrow.top:after {
    content: "\23F6";
} */

.custom-dropdown .dropdown-placeholder {
    display: block;
}

.custom-dropdown, .dropdown, .dropdown-trigger{
    width: 328px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-dropdown .dropdown .dropdown-trigger {
    display: flex;
    height: 45px;
    align-items: baseline;
    justify-content: space-between;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
}

.tag {
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
}

.tag-remove {
    padding-left: 5px;
}

.custom-dropdown .tag-item .search{
    width: 328px;
}

.custom-dropdown .dropdown .dropdown-content {
    width: 328px;
    max-height: 300px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: auto;
}

.custom-dropdown.selected .dropdown-placeholder {
    display: none;
}